<p *ngIf="!!customErrorMessage">{{ customErrorMessage }}</p>
<div class="notifications-setting-card" *ngIf="!IsTypeSelected && !!settings">
  <div class="notifications-setting-card-item" (click)="SelectFlange()">
    <div class="header">
      <span class="title"><i nz-icon nzType="icons:flange"></i> {{ i18n.string("flange") }}</span>
      <i nz-icon nzType="icons:arrow-right"></i>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("alerts") }}:</span><span
        [class.active]="settings.notificationsFlangeAlarmEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsFlangeAlarmPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("warnings") }}:</span><span
        [class.active]="settings.notificationsFlangeWarningEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsFlangeWarningPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
  </div>

  <div class="notifications-setting-card-item" (click)="SelectPipe()">
    <div class="header">
      <span class="title"><i nz-icon nzType="icons:pipe"></i> {{ i18n.string("pipe") }}</span>
      <i nz-icon nzType="icons:arrow-right"></i>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("alerts") }}:</span><span
        [class.active]="settings.notificationsPipeAlarmEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsPipeAlarmPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("warnings") }}:</span><span
        [class.active]="settings.notificationsPipeWarningEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsPipeWarningPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
  </div>

  <div class="notifications-setting-card-item" (click)="SelectSteamtrap()">
    <div class="header">
      <span class="title"><i nz-icon nzType="icons:steamtrap"></i>
        {{ i18n.string("steamtrap_non_abbrev") }}</span>
      <i nz-icon nzType="icons:arrow-right"></i>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("steam_trap_blocked") }}:</span><span
        [class.active]="settings.notificationsSteamtrapAlarmEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsSteamtrapAlarmPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("steam_trap_blow_through") }}:</span><span
        [class.active]="settings.notificationsSteamtrapWarningEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsSteamtrapWarningPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
  </div>


  <div class="notifications-setting-card-item" (click)="SelectRotatingEq()">
    <div class="header">
      <span class="title"><i nz-icon nzType="icons:rotatingEqu"></i>
        {{ i18n.string("rotatingequipment") }}</span>
      <i nz-icon nzType="icons:arrow-right"></i>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("critical") }}:</span><span
        [class.active]="settings.notificationsNanoCriticalEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsNanoCriticalPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("warning") }}:</span><span
        [class.active]="settings.notificationsNanoWarningEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsNanoWarningPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
    <div class="row">
      <span class="label">{{ i18n.string("working_fine") }}:</span><span
        [class.active]="settings.notificationsNanoWorkingEmail">{{
        i18n.string("email")
        }}</span><span [class.active]="settings.notificationsNanoWorkingPush">{{
        i18n.string("push_notification_abbrev")
        }}</span>
    </div>
  </div>
</div>

<div *ngIf="IsTypeSelected">
  <h1>{{ getTitle() }}</h1>
  <div class="toast success" *ngIf="success && !error && !permission_error">
    <i nz-icon class="inline-error-icon multicolorIcon" nzType="icons:check-circle-filled"></i>
    <span>{{ i18n.string("changes_successful") }}</span>
  </div>

  <div class="toast error" *ngIf="error">
    <i nz-icon class="inline-error-icon fillwhite" nzType="icons:error-exclamation-circle"></i>
    <span>{{ i18n.string("changes_problem") }}</span>
  </div>

  <div class="toast error" *ngIf="permission_error">
    <i nz-icon class="inline-error-icon fillwhite" nzType="icons:error-exclamation-circle"></i>
    <span>{{ i18n.string("push_permission_denied") }}</span>
  </div>

  <!-- ============================ Alerts ============================ -->
  <div class="notification-type-envelope">
    <div class="notification-type-title">
      <i nz-icon class="inline-error-icon" nzType="icons:status-exclamation-triangle"></i>
      <span>{{
        IsSteamtrap
        ? i18n.string("steam_trap_blocked")
        : IsRotatingEq
        ? i18n.string("critical")
        : i18n.string("alerts")
        }}</span>
    </div>

    <app-email-toggle [settings]="settings" [loading]="loading" [userEmail]="UserEmail"
      [(value)]="AlarmNotificationsEmail"></app-email-toggle>

    <app-push-notification-toggle [settings]="settings" [loading]="loading" [(value)]="AlarmNotificationsPush"
      [reset]="reset"></app-push-notification-toggle>
  </div>

  <!-- ============================ Warnings ============================ -->
  <div class="notification-type-envelope">
    <div class="notification-type-title">
      <i nz-icon class="inline-error-icon" nzType="icons:status-exclamation-octagon"></i>
      <span>{{
        IsSteamtrap
        ? i18n.string("steam_trap_blow_through")
        : i18n.string("warnings")
        }}</span>
    </div>

    <app-email-toggle [settings]="settings" [loading]="loading" [userEmail]="UserEmail"
      [(value)]="WarningNotificationsEmail"></app-email-toggle>

    <app-push-notification-toggle [settings]="settings" [loading]="loading" [(value)]="WarningNotificationsPush"
      [reset]="reset"></app-push-notification-toggle>
  </div>

  <!-- ============================ Working ============================ -->
  <div class="notification-type-envelope" *ngIf="IsRotatingEq">
    <div class="notification-type-title">
      <i nz-icon class="inline-error-icon" nzType="icons:status-check"></i>
      <span>{{ i18n.string("working_fine") }}</span>
    </div>

    <app-email-toggle [settings]="settings" [loading]="loading" [userEmail]="UserEmail"
      [(value)]="WorkingNotificationsEmail"></app-email-toggle>

    <app-push-notification-toggle [settings]="settings" [loading]="loading" [(value)]="WorkingNotificationsPush"
      [reset]="reset"></app-push-notification-toggle>
  </div>

  <nz-spin nzSimple [nzSize]="'large'" *ngIf="loading"></nz-spin>
</div>

<nz-modal [(nzVisible)]="pushNotificationModalVisible" (nzOnCancel)="closePushNotificationModal(false)"
  (nzOnClose)="closePushNotificationModal(false)" [nzFooter]="null" [nzCloseIcon]="'icons:exit'"
  [nzTitle]="i18n.string('push_notification')">
  <p>{{ i18n.string("push_notification_modal_text") }}</p>
  <ng-container *ngIf="!requestingNotificationPermission">
    <app-asset-nav-button (click)="requestNotificationPermission()" blackBG="true" svg_icon_right="" [center]="true"
      [bypassConnectedStatus]="true">{{ i18n.string("always") }}
    </app-asset-nav-button>
    <app-asset-nav-button id="never-button" svg_icon_right="" [center]="true" [bypassConnectedStatus]="true"
      (click)="closePushNotificationModal(false)">{{ i18n.string("never") }}
    </app-asset-nav-button>
  </ng-container>
  <app-spinner [black]="true" *ngIf="requestingNotificationPermission" class="grant-permission-spinner"></app-spinner>
</nz-modal>

<nz-modal [(nzVisible)]="!!customAlertMessage" (nzOnCancel)="closeCustomAlert()"
  (nzOnClose)="closePushNotificationModal(false)" [nzFooter]="null" [nzCloseIcon]="'icons:exit'"
  [nzTitle]="customAlertTitle">
  <p>{{ customAlertMessage }}</p>
  <app-asset-nav-button *ngIf="instructionsAddToHomeScreenIOS" (click)="DisplayAddToHomeScreenIOSInstructions()"
    blackBG="true" svg_icon_right="" [center]="true" [bypassConnectedStatus]="true">{{ i18n.string("instructions") }}
  </app-asset-nav-button>
  <app-asset-nav-button (click)="closeCustomAlert()" blackBG="true" svg_icon_right="" [center]="true"
    [bypassConnectedStatus]="true">{{ i18n.string("okay") }}
  </app-asset-nav-button>
</nz-modal>